<template>
  <div class="text-center">
    <div v-if="!started">
      <v-avatar
        rounded
        width="370"
        height="auto"
      >
        <img src="@/assets/images/careermode/gym.png">
      </v-avatar>
      <br><br>
      <p>
        As an MMA manager you can scout for new and upcoming fighters or persuade seasoned pros to
        join
        you.
      </p>
      <p>
        Secure fight contracts, obtain sponsorships and take commission on fighter
        earnings.
      </p>
      <p>
        Have you got what it takes to be a successfully MMA manager?
      </p>
    </div>
    <div v-else>
      <template>
        <v-tabs
          v-model="tab"
          color="success"
        >
          <v-tab key="1">
            Upcoming Events
          </v-tab>
          <v-tab key="2">
            My Fantasy Fighters
          </v-tab>
          <v-tab key="3">
            Gyms
          </v-tab>
          <v-tab key="4">
            MarketPlace
          </v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="tab">
        <v-tab-item key="1">
          <UpcomingEvents :career-mode="true"></UpcomingEvents>
        </v-tab-item>
        <v-tab-item key="2">
          <FantasyFighterPicker :career-home="true"></FantasyFighterPicker>
        </v-tab-item>
        <v-tab-item
          key="3"
          class="text-left"
        >
          <div class="ml-4 mt-4">
            <h3 class="text-left">
              Gyms & Training Camps
            </h3>
            <p>Improve your Fantasy Fighters by sending on Training Camps.</p>
            <p>
              Different Gym's provide different Training Camps focused on improve you're fighter's
              attributes such as
              Boxing, Wresting, Jiu Jitsu & KickBoxing.
            </p>
            <p>Training Camps vary from 1-8 weeks and is similar to "staking".</p>
            <br>
            <v-divider></v-divider>
            <br>
            <h3 class="text-left">
              Fighters Currently at Training Camps
            </h3>
            <p>None of your fighter are in active training camps.</p>
            <v-btn outlined>
              Train a Fighter
            </v-btn>
            <br>
            <br>
            <v-divider></v-divider>
            <br>
            <h3 class="text-left">
              My Gyms
            </h3>
            Earn Gold KombatCoins by owning Gyms.
            <div class="border-white my-2 pa-3 gym-item">
              <v-img
                width="600px"
                class="mx-auto"
                src="https://whitepaper.fantasykombat.com/gym.png"
              />
              <v-chip class="mx-1">
                Capacity: 3/10
              </v-chip>
              <v-chip class="mx-1">
                Weekly Cost: 1,000 KombatCoins
              </v-chip>
              <v-chip class="mx-1">
                Speciality: Muay Thai
              </v-chip>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="4">
          Market Place
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import UpcomingEvents from '@/views/components/UpcomingEvents.vue'
import FantasyFighterPicker from '@/views/components/FantasyFighterPicker.vue'

export default {
  components: {
    UpcomingEvents,
    FantasyFighterPicker,
  },
  props: {
    started: Boolean,
  },
  data() {
    return {
      tab: [],
      eventStatus: '',
      ufcEvent: '',
    }
  },
  mounted() {
    this.isMobile = this.$parent.$parent.$parent.$parent.isMobile
  },
  methods: {},
}
</script>

<style lang="scss">
.gym-item {
  border-radius: 5px;
  width: 50%;
}
</style>
