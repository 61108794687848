<template>
  <div>
    <v-row class="ma-0 pa-0 overflow-x-hidden">
      <v-col
        cols="12"
        class="col-sm-12 col-md-4"
        :order="colOrder"
      >
        <div id="trainingSliders">
          <v-slider
            v-for="(item, index) in slider"
            :key="index"
            v-model="item.val"
            :label="item.label"
            :track-fill-color="item.color"
            :thumb-color="item.color"
            thumb-label="always"
            class="mb-2 mt-12 ml-6"
            @change="trainingData(index)"
          >
            <template v-slot:thumb-label="{value}">
              <v-icon
                class="pa-4"
                dense
              >
                {{ slider[index].icon }}
              </v-icon>
              <v-badge
                class="slider-value"
                bordered
                overlap
                dense
                :content="`${value}%`"
              ></v-badge>
            </template>

            <template v-slot:append>
              <p
                v-if="slider[index].saving"
                class="text-success tsaving"
              >
                Saving...
              </p>
            </template>
          </v-slider>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="col-sm-12 col-md-8 flex-column ma-0 pa-0"
      >
        <div class="qrwrapper hidden-sm-and-down">
          <VueQrcode
            :value="qrurl"
            :options="{ width: 90, color: {dark: '#000',light: '#fff'} }"
          ></VueQrcode>
          <small class="ar-text">
            Scan to enable AR<br>
            <v-icon>{{ icons.mdiCubeScan }}</v-icon>
          </small>
        </div>
        <iframe
          id="ffviewer"
          :src="src"
          style="border: 0px;width: 100%; height: 100%;"
        ></iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import {
  mdiBatteryHeartOutline,
  mdiHeadAlertOutline,
  mdiBatteryMedium,
  mdiBottleTonicPlusOutline,
  mdiBoxingGlove,
  mdiCashMultiple,
  mdiChevronDown,
  mdiChevronUp,
  mdiDotsVertical,
  mdiFoodAppleOutline,
  mdiGamepadVariantOutline,
  mdiHeartOutline,
  mdiInformationOutline,
  mdiKabaddi,
  mdiKarate,
  mdiRunFast,
  mdiTableOfContents,
  mdiViewGridOutline,
  mdiWeightLifter,
  mdiCubeScan,
} from '@mdi/js'

Vue.use(VueCompositionApi)

export default {
  name: 'Fantasy3DViewer',
  components: {
    VueQrcode,
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiHeadAlertOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiTableOfContents,
        mdiViewGridOutline,
        mdiBatteryMedium,
        mdiBatteryHeartOutline,
        mdiHeartOutline,
        mdiBottleTonicPlusOutline,
        mdiWeightLifter,
        mdiRunFast,
        mdiKabaddi,
        mdiKarate,
        mdiBoxingGlove,
        mdiGamepadVariantOutline,
        mdiCashMultiple,
        mdiFoodAppleOutline,
        mdiInformationOutline,
        mdiCubeScan,
      },
      src: 'https://static.fantasykombat.com/3d/fantasyfighters/1a/index.html',
      qrurl: 'https://static.fantasykombat.com/3d/fantasyfighters/1a/index.html',
      colOrder: 'last',
      slider: [
        {
          id: 'cardio',
          label: 'Cardio',
          val: 10,
          icon: mdiHeartOutline,
          color: '#D4E157',
          hint: 'Stamina & Health',
          saving: false,
        },
        {
          id: 'nutrition',
          label: 'Submissions',
          val: 5,
          icon: mdiHeadAlertOutline,
          color: '#D32F2F',
          hint: 'Recovery & Health',
          saving: false,
        },
        {
          id: 'weightTraining',
          label: 'Power',
          val: 15,
          icon: mdiWeightLifter,
          color: '#8E24AA',
          hint: 'Strength',
          saving: false,
        },
        {
          id: 'wrestling',
          label: 'Wrestling',
          val: 10,
          icon: mdiKabaddi,
          color: '#A1887F',
          hint: 'Improves Wrestling "Power Ups"',
          saving: false,
        },
        {
          id: 'boxing',
          label: 'Boxing',
          val: 20,
          icon: mdiBoxingGlove,
          color: '#84FFFF',
          hint: 'Improves Boxing "Power Ups"',
          saving: false,
        },
        {
          id: 'kicks',
          label: 'Kicks',
          val: 15,
          icon: mdiKarate,
          color: '#E64A19',
          hint: 'Improves Kicks "Power Ups"',
          saving: false,
        },
      ],
    }
  },
  mounted() {
    if (!this.$vuetify.breakpoint.mobile) {
      this.colOrder = 'first'
    }
  },
}
</script>
<style>
#ffviewer {
  min-height: 600px;
}

.qrwrapper canvas {
  position: absolute;
  border-radius: 0 0 5px 0;
}

.qrwrapper {
  position: absolute;
  z-index: 1;
}

span.v-badge.slider-value.v-badge--bordered.v-badge--overlap.theme--dark {
  position: absolute;
  top: 59px;
  left: 27px;
}

#trainingSliders label.v-label.theme--dark {
  min-width: 98px;
}

#trainingSliders .v-icon svg.v-icon__svg {
  height: 20px;
}

button#sig-move {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 3px 4px;
  margin-top: 20px;
  color: #fff;
  background: #312D4B;
  opacity: 0.9;
  z-index: 2;
}

button#sig-move.hide {
  display: none;
}

small.ar-text {
  top: 91px;
  position: absolute;
  width: 90px;
  color: #fff;
  font-size: 10px;
  text-align: center;
}
</style>
