<template>
  <div id="misc">
    <v-card class="text-center d-all">
      <v-card-text>
        <h2 class="text-2xl d-all pt-6">
          <span class="me-2">Career Mode</span>
        </h2>
        <div class="mt-4">
          <CareerHome :started="started"></CareerHome>
          <div v-if="enableCareerMode">
            <v-btn
              v-if="!started "
              color="default"
              class="ma-4"
              outlined
              large
              @click="started=true"
            >
              Start Career Mode
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              color="default"
              class="ma-4"
              outlined
              large
              disabled
            >
              Coming Soon
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mdiClose, mdiBookAccountOutline} from '@mdi/js'
import CareerHome from '@/views/components/CareerHome.vue'

export default {
  components: {
    CareerHome,
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiBookAccountOutline,
      },
    }
  },
  data() {
    return {
      enableCareerMode: false,
      started: false,
    }
  },
}
</script>

<style lang="scss">
</style>
