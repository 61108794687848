<template>
  <div>
    <div
      class="ffclose"
      :class="{careerHome:careerHome}"
    >
      <v-icon
        class="ma-4 ffcloseicon"
        large
        @click="closeFfPicker"
      >
        {{ icons.mdiCloseCircleOutline }}
      </v-icon>
    </div>
    <div
      v-if="showModel"
      class="ffclose"
      :class="{careerHome:careerHome}"
    >
      <v-icon
        class="ma-4 ffcloseicon"
        large
        @click="showModel=false"
      >
        {{ icons.mdiCloseCircleOutline }}
      </v-icon>
    </div>
    <v-card v-if="!showModel">
      <div v-if="demo">
        <div class="pa-5 text--white">
          <h2 class="text-center">
            Fantasy Fighter Leagues
          </h2>
          <h3 class="text-center">
            Recruit, Train, Fight, Profit
          </h3>
          <br>
          <p>
            <strong>Recruit:</strong> Start off by recruiting amateurs, or purchase pros from
            the
            marketplace.
          </p>
          <p>
            <strong>Train:</strong> Improve your fighter's attributes and
            craft their skills by sending them to "Gyms" for training camps.
          </p>
          <p><strong>Fight:</strong> Compete against other players in weekly competitions.</p>
          <p>
            <strong class="text--white">Profit:</strong> Get rewarded for winning competitions or
            sell trained fighters
            for
            a profit on the marketplace.
          </p>
        </div>
        <hr>
        <v-card-title class="mb-0 pb-0">
          Example Fantasy Fighters
        </v-card-title>
        <small class="ml-5">(Marketplace coming soon)</small>
      </div>
      <v-card-title v-else>
        My Fantasy Fighters
      </v-card-title>
      <v-card-text>
        <template>
          <v-sheet
            class="mx-auto"
            elevation="8"
          >
            <v-slide-group
              v-model="selectedFighter"
              active-class="primary"
              center-active
              show-arrows
            >
              <v-slide-item
                v-for="fighter in ffighters"
                :key="fighter.fighter_id"
                v-slot="{ active, toggle}"
                class="text-center border-white"
              >
                <v-card
                  v-if="fighter.name"
                  class="ma-4 pa-0"
                  elevation="12"
                  height="220"
                  width="180"
                  @click="toggle"
                >
                  <h3 class="ma-1 pa-1 mt-2 mb-0 pb-0">
                    {{ fighter.name }}
                  </h3>
                  <div class="text-xs mt-0 pt-1 mb-2">
                    "{{ fighter.alias }}"<br>
                    {{ fighter.weight }} Lbs
                  </div>
                  <v-avatar
                    size="65"
                    class="mx-auto pb-0 mb-0"
                  >
                    <v-img
                      lazy-src="/img/default-profile-head.png"
                      :src="fighter.image"
                    ></v-img>
                  </v-avatar>
                  <div>
                    <country-flag
                      :country="fighter.country"
                      size="normal"
                      rounded
                      class="pick-flag pb-0 mb-0 mx-auto text-center"
                    />
                    <br>
                    <v-btn
                      v-if="!demo"
                      outlined
                      small
                      class="mr-1"
                      @click="chooseFighter"
                    >
                      Select
                    </v-btn>
                    <v-btn
                      outlined
                      small
                      class="ml-1"
                      @click="showModel=true"
                    >
                      View
                    </v-btn>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </template>
      </v-card-text>
    </v-card>
    <div
      v-if="showModel"
      style="max-width: 800px"
      class="mx-auto"
    >
      <FantasyFighterViewer></FantasyFighterViewer>
    </div>
  </div>
</template>

<script>
import {
  mdiCloseCircleOutline,
} from '@mdi/js'
import FantasyFighterViewer from '@/views/components/Fantasy3Dviewer.vue'

export default {
  components: {
    FantasyFighterViewer,
  },
  props: {
    fFighter: Object,
    nfts: Array,
    careerHome: Boolean,
  },
  data() {
    return {
      icons: {
        mdiCloseCircleOutline,
      },
      ffighters: [{
        name: 'Fred O\'Brien',
        alias: 'The FlameThrower',
        image: require('@/assets/images/fighters/demoff.jpg'),
        glb: '',
        weight: '155',
        country: 'ie',
      }],
      showModel: false,
      demo: true,
      selectedFighter: {},
      user: this.$store.state.user,
      selectedEvent: 'April-16-2022',
    }
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedFighter = this.ffighters[0]
  },
  filter: {
    splitLine(value) {
      return value.replace(' ', '<br>')
    },
  },
  methods: {
    chooseFighter() {
      console.log(this.selectedFighter)
      this.$emit('updateFF', this.ffighters)
    },

    closeFfPicker() {
      console.log('closes')
      this.$emit('updateffPicker', false)
    },
  },
}
</script>
<style>
span.pick-flag {
  margin-top: -20px;
  border-radius: 9px;
}

.choose-power-up-fighter .v-card__title {
  min-height: 43px;
  text-align: center;
  display: inline-block;
}

.borderwin {
  border: 2px solid #56ca00 !important;
}

.ffclose {
  position: relative;
  z-index: 1;
  float: right;
  margin-bottom: -68px;
}

.border-white {
  border: 2px solid #fff !important;
}

strong {
  color: #fff;
}
</style>
